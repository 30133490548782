import { useMemo } from 'react';
import { AnswerValue } from '../../../../types';
import Button from '../../components/Button';
import { useQuizContext } from '../context';

type Props = {
  readonly loading: boolean;
  readonly currentPage: number;
  readonly totalPages: number;
  readonly onPrev: () => void;
  readonly onNext: () => void;
  readonly onSubmit: () => void;
};

export default function Controls({
  loading,
  currentPage,
  totalPages,
  onPrev,
  onNext,
  onSubmit,
}: Props) {
  const { quiz, answers } = useQuizContext();

  const validationErrors = useMemo(() => {
    const page = quiz.pages[currentPage];
    const questions = quiz.questions.filter((question) =>
      page.questionKeys.includes(question.key),
    );
    const values: Record<string, AnswerValue> = Object.entries(answers)
      .filter(([questionKey]) => page.questionKeys.includes(questionKey))
      .reduce(
        (data, [questionKey, value]) => ({ ...data, [questionKey]: value }),
        {},
      );

    const errors = questions.map((question, idx) => {
      const answer = values[question.key];

      if (question.type === 'TEXT') {
        if (answer.length < question.required) {
          return `${question.text} is required`;
        }
      } else if (question.type === 'RANK') {
        if (question.required && !answer) {
          return `Question ${idx + 1} is required`;
        }
      } else {
        // Array type answers.
        const value = Array.isArray(answer) ? answer : [answer];

        if (value.length < question.required) {
          return `${question.required} selections required`;
        }
        if (value.length > question.required) {
          return `${question.required} selections required`;
        }
      }

      return null;
    });

    return errors.filter((error) => error !== null);
  }, [quiz, answers, currentPage]);

  return (
    <div className="flex relative justify-between group">
      {validationErrors.length > 0 && (
        <div className="absolute hidden left-0 right-0 top-0 -translate-y-full -mt-7 bg-ui-background border border-red-500 text-red-500 px-3 py-2 rounded-sm shadow-md">
          {validationErrors.join(', ')}
        </div>
      )}

      <div>
        {currentPage > 0 && <Button onClick={onPrev}>Prev</Button>}
        {currentPage == 0 && <Button onClick={onPrev}>Back</Button>}
      </div>

      <div>
        {currentPage < totalPages - 1 && (
          <Button disabled={validationErrors.length > 0} onClick={onNext}>
            Next
          </Button>
        )}
        {currentPage === totalPages - 1 && (
          <Button
            loading={loading}
            disabled={validationErrors.length > 0}
            onClick={onSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}
