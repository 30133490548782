import ReactMarkdown from 'react-markdown';
import Container from '../../components/Container';
import { Response } from '../queries/getQuizQuery';
import Question from './Question';

type Props = {
  readonly body: string | null;
  readonly questions: Response['quiz']['questions'];
  readonly allQuestionKeys: readonly string[];
};

export default function Page({ body, questions, allQuestionKeys }: Props) {
  return (
    <section className="flex items-start justify-center">
      <Container>
        {body && (
          <div className="prose prose-invert max-w-none mb-5 xl:mb-10">
            <ReactMarkdown>{body.replace(/\\n/g, '  \n')}</ReactMarkdown>
          </div>
        )}

        <div className="space-y-5 xl:space-y-10">
          {questions.map((question) => (
            <Question
              number={allQuestionKeys.indexOf(question.key) + 1}
              key={question.key}
              question={question}
            />
          ))}
        </div>
      </Container>
    </section>
  );
}
