import Header from './components/Header';

export default function Thanks() {
  return (
    <div className="min-h-screen flex flex-col bg-ui-background text-white">
      <div className="flex-shrink-0">
        <Header />
      </div>
      <div className="flex flex-grow items-center justify-center">
        <div className="p-5">
            You have now completed the survey, and your answers have been submitted.<br />
            Thank you for your participation.
        </div>
      </div>
    </div>
  );
}
