import { Response } from '../../SurveyView/queries/getQuizQuery';
import Checkbox from './Checkbox';

type Props = {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly options: Response['quiz']['questions'][number]['options'];
};

export default function RankInput({ value, onChange, options }: Props) {
  return (
    <div className="flex align-bottom justify-evenly flex-wrap sm:flex-nowrap">
      {options.map((option) => (
        <div
          key={option.value}
          className="flex flex-col justify-end items-center space-y-2 w-full"
        >
          <div className="w-full text-center px-2 text-sm">{option.text}</div>
          <div className="bg-black bg-opacity-30 px-2 py-3 w-full flex justify-center">
            <Checkbox
              checked={value === option.value}
              onClick={() => onChange(option.value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
